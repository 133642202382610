const sectionBgBase = 'bg-gradient-to-tr'
export const sectionBgLogin = `${sectionBgBase} from-purple-400 via-pink-500 to-red-500`
export const sectionBgLoginDark = `${sectionBgBase} from-purple-900 via-pink-900 to-red-900`
export const sectionBgError = `${sectionBgBase} from-pink-400 via-red-500 to-yellow-500`
export const sectionBgErrorDark = `${sectionBgBase} from-pink-900 via-red-900 to-yellow-900`

export const colorsBg = {
  white: 'bg-white text-black',
  light: 'bg-gray-100 text-black',
  success: 'bg-successAdmin text-white',
  danger: 'bg-dangerAdmin text-white',
  warning: 'bg-warningAdmin text-white',
  info: 'bg-blue-500 text-white',
  blueLightNatonal: 'bg-blueLightNat text-white',
  blueNatonal: 'bg-blueNat text-white',
  greyNatonal: 'bg-greyNat text-black',
  orangeNatonal: 'bg-orangeNat text-white',
  blackNatonal: 'bg-blackNat text-white',
  whiteNatonal: 'bg-white text-black',
  greenLightNatonal: 'bg-greenLightNat text-white',
  redLightNatonal: 'bg-redLightNat text-white',
  add: 'bg-white',
  darkred: 'bg-darkRed text-white'
}

export const colorsBgHover = {
  white: 'hover:bg-gray-50',
  light: 'hover:bg-gray-200',
  success: 'hover:bg-emerald-700',
  danger: 'hover:bg-red-700',
  warning: 'hover:bg-yellow-700',
  info: 'hover:bg-blue-700',
  blueLightNatonal: 'hover:bg-blueLightNatHover',
  blueNatonal: 'hover:bg-blueNatHover',
  greyNatonal: 'hover:bg-greyNatHover',
  whiteNatonal: 'hover:bg-blackNatHover hover:text-white',
  orangeNatonal: 'hover:bg-orangeNatHover',
  blackNatonal: 'hover:bg-blackNatHover text-white',
  greenLightNatonal: 'hover:bg-greenLightNatHover',
  redLightNatonal: 'hover:bg-redLightNatHover',
  add: 'hover:bg-black hover:bg-opacity-5'
}

export const colorsBorders = {
  white: 'border-gray-300',
  light: 'border-gray-200 dark:border-gray-400',
  success: 'border-emerald-700',
  danger: 'border-red-700',
  warning: 'border-yellow-700',
  info: 'border-blue-700',
  blueLightNatonal: 'border-blueLightNat',
  blueNatonal: 'border-blueNat',
  greyNatonal: 'border-greyNat',
  orangeNatonal: 'border-orangeNat',
  blackNatonal: 'border-blackNat',
  whiteNatonal: 'border-blackNat',
  redLightNatonal: 'border-redLightNat',
  greenLightNatonal: 'border-greenLightNat',
  add: 'border-blue-400 '
}



// color-text for outline btn
export const colorsText = {
  white: 'text-black dark:text-gray-100',
  light: 'text-gray-700 dark:text-gray-400',
  success: 'text-emerald-600',
  danger: 'text-red-600',
  warning: 'text-yellow-600',
  info: 'text-blue-600',
  blueLightNatonal: 'text-blueLightNat',
  blueNatonal: 'text-blueNat',
  grayNatonal: 'text-black',
  orangeNatonal: 'text-blueNat',
  blackNatonal: 'text-white',
  whiteNatonal: 'text-black',
  greenLightNatonal: 'text-greenLightNat',
  redLightNatonal: 'text-redLightNat',
  add: 'text-blue-400',

}

export const colorsOutline = {
  white: [colorsText.white, colorsBorders.white],
  light: [colorsText.light, colorsBorders.light],
  success: [colorsText.success, colorsBorders.success],
  danger: [colorsText.danger, colorsBorders.danger],
  warning: [colorsText.warning, colorsBorders.warning],
  info: [colorsText.info, colorsBorders.info],
  blueLightNatonal: [colorsText.blueLightNatonal, colorsBorders.blueLightNatonal],
  blueNatonal: [colorsText.blueNatonal, colorsBorders.blueNatonal],
  greyNatonal: [colorsText.greyNatonal, colorsBorders.greyNatonal],
  orangeNatonal: [colorsText.orangeNatonal, colorsBorders.orangeNatonal],
  blackNatonal: [colorsText.blackNatonal, colorsBorders.blackNatonal],
  whiteNatonal: [colorsText.whiteNatonal, colorsBorders.whiteNatonal],
  greenLightNatonal: [colorsText.greenLightNatonal, colorsBorders.greenLightNatonal],
  redLightNatonal: [colorsText.redLightNatonal, colorsBorders.redLightNatonal],
  add: [colorsText.add, colorsBorders.add]
}

export const colorsOutlineHover = {
  white: 'hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-900',
  light: 'hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-900',
  success: 'hover:bg-emerald-700 hover:text-white',
  danger: 'hover:bg-red-700 hover:text-white',
  warning: 'hover:bg-yellow-700 hover:text-white',
  info: 'hover:bg-blue-700 hover:text-white',
  blueLightNatonal: 'hover:bg-blueLightNat hover:text-white',
  blueNatonal: 'hover:bg-blueNat hover:text-white',
  blackNatonal: 'hover:bg-whiteNat hover:text-black',
  whiteNatonal: 'hover:bg-blackNat hover:text-white',
  greyNatonal: 'hover:bg-greyNat hover:text-white',
  orangeNatonal: 'hover:bg-orangeNat hover:text-white',
  greenLightNatonal: 'hover:bg-greenLightNat hover:text-white',
  redLightNatonal: 'hover:bg-redLightNat hover:text-white',
  add: 'hover:bg-black hover:bg-opacity-5'
}

export const getButtonColor = (color, isOutlined, hasHover) => {
  const base = [
    isOutlined ? colorsText[color] : colorsBg[color],
    colorsBorders[color]
  ]

  if (hasHover) {
    base.push(isOutlined ? colorsOutlineHover[color] : colorsBgHover[color])
  }

  return base
}
